<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal information</span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">User Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div class="image-input-wrapper" :style="{ backgroundImage: `url(${photo})` }"></div>
              <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove" data-toggle="tooltip" title="Remove avatar" @click="current_photo = null">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">NIK</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="nik" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.nik" disabled />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Initial*</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="inisiaal" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.code" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Name</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="name" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.name" disabled />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Gender</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="name" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.sex" disabled />
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Job Info</h5>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Functional</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.functional" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Functional Title</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.functional_title" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Position</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.position ? dataUser.pic.position.name : ''" disabled />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Directorat</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.directorate" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Division</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.division" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Department</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="functional" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.department" disabled />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Company Name</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="company_name" class="form-control form-control-lg form-control-solid" type="text"
              v-bind:value="dataUser.pic.company" disabled />
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Contact Phone</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input ref="phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone"
                v-bind:value="dataUser.pic.phone" disabled />
            </div>
            <span class="form-text text-muted">We'll never share your email with anyone else.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Email Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input ref="email" type="text" class="form-control form-control-lg form-control-solid" placeholder="Email"
                v-bind:value="dataUser.email" disabled />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { POST_UPLOAD_AVATAR, GET_USER_DETAIL_BY_ID } from "@/core/services/store/user.module";
import { showToast } from "@/core/helper/toast.helper";

export default {
  name: "PersonalInformation",
  props: {
    dataUser: {
      required: true
    }
  },
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null
    };
  },
  mounted() {

    this.current_photo = this.currentLogin.pic.avatar;
  },
  methods: {
    onGetUserDetail(id) {
      this.$store.dispatch(GET_USER_DETAIL_BY_ID, id).then(() => this.c);
    },
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },

    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
        this.UploadAvatar(this.currentLogin.pic_code, file)
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    UploadAvatar(code, files) {
      if (files !== undefined) {
        let formData = new FormData();
        formData.append("avatar", files);
        formData.append("code", code);
        this.$store
          .dispatch(POST_UPLOAD_AVATAR, formData)
          .then(() => {
            this.onGetUserDetail(this.currentLogin.data.id)
            showToast(
              "Notifikasi",
              "Upload Avatar berhasil disimpan",
              "success"
            );
          })
          .catch(error =>
            showToast("Notifikasi", error.data.message, "danger")
          );
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserDetail", "currentUserPersonalInfo"]),
    currentLogin() {
      return this.currentUserDetail;
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
